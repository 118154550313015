*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1 {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
}
